// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/BlogIndex.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/DefaultPage.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/HomePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-nutrition-index-js": () => import("./../../../src/templates/NutritionIndex.js" /* webpackChunkName: "component---src-templates-nutrition-index-js" */),
  "component---src-templates-recipe-post-js": () => import("./../../../src/templates/RecipePost.js" /* webpackChunkName: "component---src-templates-recipe-post-js" */),
  "component---src-templates-services-page-js": () => import("./../../../src/templates/ServicesPage.js" /* webpackChunkName: "component---src-templates-services-page-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/SinglePost.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

